@font-face {
    font-family: "BrownProTT";
    src: local("BrownProTT"),
    url(./fonts/BrownProTT/BrownProTT-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "BrownProTTLight";
    src: local("BrownProTT"),
    url(./fonts/BrownProTT/BrownProTT-Light.ttf) format("truetype");

}

@font-face {
    font-family: "BrownProTTBold";
    src: local("BrownProTT"),
    url(./fonts/BrownProTT/BrownProTT-Bold.ttf) format("truetype");

}


body {
    margin: 0;
    font-family: "BrownProTT", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F7F7F7;
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/
